import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      /* lao */
      @font-face {
        font-family: 'Phetsarath';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('./static/fonts/phetsarath-lao-700-normal.woff2') format('woff2'), url('./static/fonts/phetsarath-lao-700-normal.woff') format('woff');
        unicode-range: U+0E80-0EFF;
      }
      /* lao */
      @font-face {
        font-family: 'Phetsarath';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./static/fonts/phetsarath-lao-400-normal.woff2') format('woff2'), url('./static/fonts/phetsarath-lao-400-normal.woff') format('woff');
        unicode-range: U+0E80-0EFF;
      }
      `}
  />
)

export default Fonts
